"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiniteStateMachine = void 0;
// Import event source
const event_source_1 = require("../../event-source/event-source");
class FiniteStateMachine extends event_source_1.EventSource {
    constructor(stateDiagram) {
        super();
        this.stateDiagram = stateDiagram;
        // Build state action map
        this.stateActionMap = this.stateDiagram.stateActions.reduce((map, action) => {
            map[action.state] = action;
            return map;
        }, {});
        this.currentState = 'START';
        this.stateHistory = [this.currentState];
    }
    /* --- Getters and setters --- */
    get currentStateAction() {
        return this.stateActionMap[this.currentState];
    }
    get stateHistoryList() {
        return this.stateHistory.slice(0);
    }
    /* --- Functions --- */
    triggerDone() {
        this.triggerEvent('done', null);
    }
    fireEvent(event) {
        // Store the current state as previous
        const prevState = this.currentState;
        // Attempt to find state transition
        const transition = this.stateDiagram.stateTransitions.find(transition => transition.from === this.currentState && transition.event === event) ||
            this.stateDiagram.stateTransitions.find(transition => transition.from === '*' && transition.event === event);
        // Transition to new state
        if (transition) {
            this.currentState = transition.to;
            this.stateHistory.push(this.currentState);
        }
        // Build result
        const result = {
            endStateReached: this.currentState === 'END',
            previousState: prevState,
            currentState: this.currentState,
            lastEvent: event,
            ruleApplied: !!transition,
            stateHistory: this.stateHistory,
            options: transition === null || transition === void 0 ? void 0 : transition.options
        };
        // Call complete callback if state machine is done
        if (this.currentState === 'END')
            this.triggerEvent('done', result);
        // return result
        return result;
    }
    on(event, handler) { super.on(event, handler); }
    off(event, handler) { super.off(event, handler); }
}
exports.FiniteStateMachine = FiniteStateMachine;
